import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Section, SectionWrapper, Title } from "../../core/commonExports"
import { Button } from "../../core/Button"

const FooterSection = ({handleGetQuoteClick, glp2v1 = false, sectionBg="#fff", hideQuoteCTA = false}) => {
    return (
      <Section background={sectionBg} className="position-relative">
        <SectionWrapper>
          <Container style={{width: '100%', position: 'relative', alignItems: 'center'}}>
            <HeadingContainer style={{marginLeft: '0', maxWidth: '430px'}}>
              <Title fontSize="36px" mfontSize="24px" style={{ marginBottom: '40px' }}>
                {glp2v1 ? "Insure" : "Secure"} your commercial property against burglary, fire, flood and 15+ threats today!
              </Title>
              <div className="on-desktop">
                {!hideQuoteCTA && <Button label="GET A QUOTE" wide onClick={handleGetQuoteClick} />}
              </div>
            </HeadingContainer>
            <div style={{width: "min(605px, 100%)", height: "auto"}}>
              <StaticImage
                src="../../../assets/images/sweets-shop.webp"
                alt="secure your business"
                style={{marginLeft: '1.5rem'}}
                loading='lazy'
                width={605}
                height={468}
              />
            </div>
            <div className="on-mobile">
              {!hideQuoteCTA && <Button label="GET A QUOTE" wide style={{margin: 'auto', marginTop: '2rem'}} onClick={handleGetQuoteClick} />}
            </div>
          </Container>
        </SectionWrapper>
      </Section>
    )
}

const HeadingContainer = styled.div`
  margin-left: 150px;
  width: 100%;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div`
  margin: 50px 0px;
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: ${({ reverse }) =>
      `${reverse ? "column-reverse" : "column"}`};
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export default FooterSection